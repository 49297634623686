import * as React from 'react';
import { Progress } from '@/types/progress';

const initialContext = {
  progress: undefined,
  refetch: () => {},
};

interface DataContextType {
  progress: Progress | undefined;
  refetch: () => void;
}
const DataContext = React.createContext<DataContextType>(initialContext);

export type { DataContextType };
export { DataContext };
