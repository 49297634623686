import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { userService } from '@/services/user';
import { User } from '@/types/user';
import Sentry from '@/utils/sentry';
import { localStorageKeys } from '@/constants/local-storage';

type AuthContext = {
  loggedIn: boolean;
  token: string | null;
  login: (data: { email: string; password: string }) => Promise<any>;
  logout: () => void;
  user: User | null;
};

const AuthContext = React.createContext<AuthContext>({
  loggedIn: false,
  token: null,
  login: async () => {},
  logout: () => {},
  user: null,
});

const getLocalToken = () => localStorage.getItem(localStorageKeys.token);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const fetchingRef = React.useRef(false);
  const [state, setState] = React.useState({
    loggedIn: Boolean(getLocalToken()),
    token: getLocalToken(),
    user: null as User | null,
  });

  const queryClient = useQueryClient();

  const login = async ({ email, password }: { email: string; password: string }) => {
    const res = await userService.login({ email, password });

    localStorage.setItem(localStorageKeys.token, res.accessToken);
    localStorage.setItem(localStorageKeys.tokenRefreshedAt, new Date().toISOString());

    setState((prev) => ({ ...prev, token: res.accessToken, loggedIn: true }));

    return res;
  };

  const logout = () => {
    localStorage.clear();
    setState((prev) => ({ ...prev, token: null, loggedIn: false }));
    queryClient.clear();
    Sentry.setUser(null);
  };

  const refreshToken = async () => {
    try {
      const data = await userService.refreshToken();

      localStorage.setItem(localStorageKeys.token, data.accessToken);
      localStorage.setItem(localStorageKeys.tokenRefreshedAt, new Date().toISOString());

      setState((prev) => ({ ...prev, token: data.accessToken }));

      return true;
    } catch (e: any) {
      const res: Response = e;

      if (res.status === 401) {
        logout();

        return false;
      }
    }

    return true;
  };

  React.useEffect(() => {
    const effect = async () => {
      if (fetchingRef.current) {
        return;
      }

      fetchingRef.current = true;

      if (!state.token) {
        setState((prev) => ({ ...prev, user: null, loggedIn: false }));

        fetchingRef.current = false;

        return;
      }

      try {
        const data = await userService.get();

        localStorage.setItem(localStorageKeys.userId, data._id);

        Sentry.setUser({ id: data._id });

        setState((prev) => ({ ...prev, user: data }));
      } catch (e: any) {
        const res: Response = e;

        if (res.status === 401) {
          fetchingRef.current = false;

          return logout();
        }
      }

      const lastRefreshedAt = localStorage.getItem(localStorageKeys.tokenRefreshedAt);
      const lastRefreshedAtDate = new Date(lastRefreshedAt || 0);
      const now = new Date();

      const hasntBeenRefreshedSinceOneDay =
        now.getTime() - lastRefreshedAtDate.getTime() > 1000 * 60 * 60 * 24;

      if (!lastRefreshedAt || hasntBeenRefreshedSinceOneDay) {
        refreshToken();
      }

      const timeoutId = setTimeout(refreshToken, 1000 * 60 * 60 * 24); // 1 day

      fetchingRef.current = false;

      return () => {
        clearTimeout(timeoutId);
        fetchingRef.current = false;
      };
    };

    effect();
  }, [state.token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider value={{ login, logout, ...state }}>{children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
