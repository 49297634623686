import * as React from 'react';
import { localStorageKeys } from '@/constants/local-storage';
import { DataContext } from '@/context/data-context';
import { Episode } from '@/types/episode';
import { useParams } from 'react-router-dom';
import boxes from '@/data/app-2/boxes';
import APP_2 from '@/data/app-2/game';
import WOODLOCK from '@/data/woodlock/game';

type GameId = typeof APP_2.id | typeof WOODLOCK.id;

function useBox<T extends boolean>(gameId: GameId) {
  const { boxId } = useParams<{ boxId: string | undefined }>();
  const id = (boxId ? Number(boxId) : undefined) as T extends false
    ? Episode['id'] | undefined
    : Episode['id'];

  const { progress } = React.useContext(DataContext);

  const status = ((id && progress?.boxes[`box_${id as 1 | 2 | 3}`]?.status) ||
    localStorage.getItem(
      (localStorageKeys[gameId] || localStorageKeys).boxStatus
    )) as Episode['status'];

  React.useEffect(() => {
    if (id && status) {
      localStorage.setItem(
        (localStorageKeys[gameId] || localStorageKeys).currentBox,
        id.toString()
      );
      localStorage.setItem(
        (localStorageKeys[gameId] || localStorageKeys).boxStatus,
        status.toString()
      );
    }
  }, [boxId]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ...boxes[(id || 0) - 1], id, status };
}

export default useBox;
