import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '@/context/auth-context';

/**
 * Ce composant permet d'encapsuler l'application + la page Parametre + la page Credit.
 * Si l'utilisateur n'est pas connecté, alors on retourne sur la page Sign-in
 */
const Restrictedaccess = () => {
  const { loggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!loggedIn) {
      navigate('/sign-in');
    }
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!loggedIn) {
    return null;
  }

  return <Outlet />;
};

export default Restrictedaccess;
